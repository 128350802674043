import { createSlice } from "@reduxjs/toolkit";
import { fetchActiveUser, fetchGifts, fetchListTrendingStreams, fetchStreamersLeaderBoard, fetchTotalCountByUserType, fetchTotalGiftSends, fetchTotalLiveViewersByStreamer, fetchTotalLiveViewersNowByStreamer, fetchTotalNewStreamers, fetchTotalNewUsers, fetchWeeklyNewFollowersByStreamer } from "./actions";

const initialState = {
  data: null,
  totalNewUsers: 0,
  totalNewStreamers: {
    isLoading: false,
    success: null,
    error: null,
    data: null,
  },
  totalGiftSends: 0,
  streamerLeaderBoard: null,
  isLoading: false,
  error: null,
  success: null,
  message: null,
  activeOrganizations: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
  activeAgencies: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
  activeStreamers: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
  trendingStreams: {
    data: null,
    userDetails: null,
    isLoading: false,
    error: null,
    success: null,
    message: null
  },
  organizationTotalCount: {
    isLoading: false,
    success: null,
    error: null,
    data: null,
  },
  agencyTotalCount: {
    isLoading: false,
    success: null,
    error: null,
    data: null,
  },
  streamerTotalCount: {
    isLoading: false,
    success: null,
    error: null,
    data: null,
  },
  gifts: {
    isLoading: false,
    success: null,
    error: null,
    data: null,
  },
  weeklyFollowers: {
    isLoading: false,
    success: null,
    error: null,
    data: null,
  },
  totalLiveViewers: {
    isLoading: false,
    success: null,
    error: null,
    data: null,
  },
  totalLiveViewersNow: {
    isLoading: false,
    success: null,
    error: null,
    data: null,
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    forceClearAllUserState: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTotalNewUsers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchTotalNewUsers.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.totalNewUsers = data.total;
        state.isLoading = false;
        state.success = `Successfully Fetched Data`;
        state.message = message;
      })
      .addCase(fetchTotalNewUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || `Error Fetched Data`;
        state.message = action.payload.m || "An error occurred";
      })

      .addCase(fetchTotalNewStreamers.pending, (state, action) => {
        state.totalNewStreamers.isLoading = true;
      })
      .addCase(fetchTotalNewStreamers.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.totalNewStreamers.data = data.total;
        state.totalNewStreamers.isLoading = false;
        state.totalNewStreamers.success = `Successfully Fetched Data`;
        state.totalNewStreamers.message = message;
      })
      .addCase(fetchTotalNewStreamers.rejected, (state, action) => {
        state.totalNewStreamers.isLoading = false;
        state.totalNewStreamers.success = false;
        state.totalNewStreamers.error = action.payload.m || `Error Fetched Data`;
        state.totalNewStreamers.message = action.payload.m || "An error occurred";
      })

      .addCase(fetchTotalGiftSends.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchTotalGiftSends.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.totalGiftSends = data;
        state.isLoading = false;
        state.success = `Successfully Fetched Data`;
        state.message = message;
      })
      .addCase(fetchTotalGiftSends.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || `Error Fetched Data`;
        state.message = action.payload.m || "An error occurred";
      })

      .addCase(fetchStreamersLeaderBoard.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchStreamersLeaderBoard.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.streamerLeaderBoard = data;
        state.isLoading = false;
        state.success = `Successfully Fetched Data`;
        state.message = message;
      })
      .addCase(fetchStreamersLeaderBoard.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || `Error Fetched Data`;
        state.message = action.payload.m || "An error occurred";
      })

      // THIS IS REUSABLE FOR FETCHING Active USERS, ORGANIZATION, AGENCIES AND STREAMERS
      .addCase(fetchActiveUser.pending, (state, action) => {
        const { userType } = action.meta.arg
        switch (userType) {
          case "organizations":
            state.activeOrganizations.isLoading = true;
            break;
          case "agencies":
            state.activeAgencies.isLoading = true;
            break;
          case "streamers":
            state.activeStreamers.isLoading = true;
            break;
        
          default:
            break;
        }
      })
      .addCase(fetchActiveUser.fulfilled, (state, action) => {
        const { userType } = action.meta.arg
        const data = action.payload.d;
        const message = action.payload.m;

        switch (userType) {
          case "organizations":
            state.activeOrganizations.data = data;
            state.activeOrganizations.isLoading = false;
            state.activeOrganizations.success = `Successfully Fetched ${userType} Data`;
            state.activeOrganizations.message = message;
            break;
          case "agencies":
            state.activeAgencies.data = data;
            state.activeAgencies.isLoading = false;
            state.activeAgencies.success = `Successfully Fetched ${userType} Data`;
            state.activeAgencies.message = message;
            break;
          case "streamers":
            state.activeStreamers.data = data;
            state.activeStreamers.isLoading = false;
            state.activeStreamers.success = `Successfully Fetched ${userType} Data`;
            state.activeStreamers.message = message;
            break;
        
          default:
            break;
        }

      })
      .addCase(fetchActiveUser.rejected, (state, action) => {
        const { userType } = action.meta.arg
        switch (userType) {
          case "organizations":
            state.activeOrganizations.isLoading = false;
            state.activeOrganizations.error = action.payload.m || `Error Fetched ${userType} Data`;
            state.activeOrganizations.message = action.payload.m || "An error occurred";
            break;
          case "agencies":
            state.activeAgencies.isLoading = false;
            state.activeAgencies.error = action.payload.m || `Error Fetched ${userType} Data`;
            state.activeAgencies.message = action.payload.m || "An error occurred";
            break;
          case "streamers":
            state.activeStreamers.isLoading = false;
            state.activeStreamers.error = action.payload.m || `Error Fetched ${userType} Data`;
            state.activeStreamers.message = action.payload.m || "An error occurred";
            break;
        
          default:
            break;
        }
      })

      .addCase(fetchListTrendingStreams.pending, (state, action) => {
        state.trendingStreams.isLoading = true;
      })
      .addCase(fetchListTrendingStreams.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.trendingStreams.data = data;
        state.trendingStreams.isLoading = false;
        state.trendingStreams.success = `Successfully Fetched Data`;
        state.trendingStreams.message = message;
      })
      .addCase(fetchListTrendingStreams.rejected, (state, action) => {
        state.trendingStreams.isLoading = false;
        state.trendingStreams.success = false;
        state.trendingStreams.error = action.payload.m || `Error Fetched Data`;
        state.trendingStreams.message = action.payload.m || "An error occurred";
      })

      // THIS IS REUSABLE FOR TOTAL COUNT BY USERS, ORGANIZATION, AGENCIES AND STREAMERS
      .addCase(fetchTotalCountByUserType.pending, (state, action) => {
        const { totalType } = action.meta.arg
        state[`${totalType}TotalCount`].isLoading = true;
      })
      .addCase(fetchTotalCountByUserType.fulfilled, (state, action) => {
        const { totalType } = action.meta.arg
        const data = action.payload.d;
        const message = action.payload.m;

        state[`${totalType}TotalCount`].data = data;
        state[`${totalType}TotalCount`].isLoading = false;
        state[`${totalType}TotalCount`].success = `Successfully Fetched`;
        state[`${totalType}TotalCount`].message = message;

      })
      .addCase(fetchTotalCountByUserType.rejected, (state, action) => {
        const { totalType } = action.meta.arg
        state[`${totalType}TotalCount`].isLoading = false;
        state[`${totalType}TotalCount`].success = false;
        state[`${totalType}TotalCount`].error = action.payload.m || `Error Fetched`;
        state[`${totalType}TotalCount`].message = action.payload.m || "An error occurred";
      })

      .addCase(fetchGifts.pending, (state, action) => {
        state.gifts.isLoading = true;
      })
      .addCase(fetchGifts.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.gifts.data = data;
        state.gifts.isLoading = false;
        state.gifts.success = `Successfully Fetched Data`;
        state.gifts.message = message;
      })
      .addCase(fetchGifts.rejected, (state, action) => {
        state.gifts.isLoading = false;
        state.gifts.success = false;
        state.gifts.error = action.payload.m || `Error Fetched Data`;
        state.gifts.message = action.payload.m || "An error occurred";
      })

      .addCase(fetchWeeklyNewFollowersByStreamer.pending, (state, action) => {
        state.weeklyFollowers.isLoading = true;
      })
      .addCase(fetchWeeklyNewFollowersByStreamer.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.weeklyFollowers.data = data;
        state.weeklyFollowers.isLoading = false;
        state.weeklyFollowers.success = `Successfully Fetched Data`;
        state.weeklyFollowers.message = message;
      })
      .addCase(fetchWeeklyNewFollowersByStreamer.rejected, (state, action) => {
        state.weeklyFollowers.isLoading = false;
        state.weeklyFollowers.success = false;
        state.weeklyFollowers.error = action.payload.m || `Error Fetched Data`;
        state.weeklyFollowers.message = action.payload.m || "An error occurred";
      })

      .addCase(fetchTotalLiveViewersByStreamer.pending, (state, action) => {
        state.totalLiveViewers.isLoading = true;
      })
      .addCase(fetchTotalLiveViewersByStreamer.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.totalLiveViewers.data = data;
        state.totalLiveViewers.isLoading = false;
        state.totalLiveViewers.success = `Successfully Fetched Data`;
        state.totalLiveViewers.message = message;
      })
      .addCase(fetchTotalLiveViewersByStreamer.rejected, (state, action) => {
        state.totalLiveViewers.isLoading = false;
        state.totalLiveViewers.success = false;
        state.totalLiveViewers.error = action.payload.m || `Error Fetched Data`;
        state.totalLiveViewers.message = action.payload.m || "An error occurred";
      })

      .addCase(fetchTotalLiveViewersNowByStreamer.pending, (state, action) => {
        state.totalLiveViewersNow.isLoading = true;
      })
      .addCase(fetchTotalLiveViewersNowByStreamer.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        state.totalLiveViewersNow.data = data;
        state.totalLiveViewersNow.isLoading = false;
        state.totalLiveViewersNow.success = `Successfully Fetched Data`;
        state.totalLiveViewersNow.message = message;
      })
      .addCase(fetchTotalLiveViewersNowByStreamer.rejected, (state, action) => {
        state.totalLiveViewersNow.isLoading = false;
        state.totalLiveViewersNow.success = false;
        state.totalLiveViewersNow.error = action.payload.m || `Error Fetched Data`;
        state.totalLiveViewersNow.message = action.payload.m || "An error occurred";
      })
      
  },
});

export const { 
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
