import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "helpers/api_service";
import buildQueryString from "helpers/buildQueryString";
// import buildQueryString from "helpers/buildQueryString";

export const fetchTotalNewUsers = createAsyncThunk(
  "dashboard/fetchTotalNewUsers",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    // const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/dashboards/total-new-users`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchTotalNewStreamers = createAsyncThunk(
  "dashboard/fetchTotalNewStreamers",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    // const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/dashboards/total-new-streamers`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchTotalGiftSends = createAsyncThunk(
  "dashboard/fetchTotalGiftSends",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    // const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/dashboards/total-gift-sends`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const getTotalNewFollowers = createAsyncThunk(
  "dashboard/getTotalNewFollowers",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    // const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/dashboards/total-new-followers`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchStreamersLeaderBoard = createAsyncThunk(
  "dashboard/fetchStreamersLeaderBoard",
  async ({ queryParams }, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/dashboards/streamers-leaderboard?${queryString}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchListTrendingStreams = createAsyncThunk(
  "dashboard/fetchListTrendingStreams",
  async ({ queryParams }, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/dashboards/list-trending-streams?${queryString}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchActiveUser = createAsyncThunk(
  "dashboard/fetchActiveUser",
  async ({ userType, queryParams }, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/dashboards/list-active-${userType === "users" ? "" : userType}?${queryString}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);


export const fetchTotalCountByUserType = createAsyncThunk(
  "dashboard/fetchTotalCountByUserType",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    // const queryString = buildQueryString(queryParams);
    try {
      const response = await apiService.get(`/${payload?.userType || "users"}/total-all-${payload?.totalType || "user"}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchGifts = createAsyncThunk(
  "dashboard/fetchGifts",
  async ({ queryParams }, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(queryParams || {});
    try {
      const response = await apiService.get(`/gifts?${queryString}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchWeeklyNewFollowersByStreamer = createAsyncThunk(
  "dashboard/fetchWeeklyNewFollowersByStreamer",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(payload?.queryParams || {});
    try {
      const response = await apiService.get(`/streamers/total-weekly-followers?${queryString}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchTotalLiveViewersByStreamer = createAsyncThunk(
  "dashboard/fetchTotalLiveViewersByStreamer",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(payload?.queryParams || {});
    try {
      const response = await apiService.get(`/streamers/total-live-viewers?${queryString}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchTotalLiveViewersNowByStreamer = createAsyncThunk(
  "dashboard/fetchTotalLiveViewersNowByStreamer",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(payload?.queryParams || {});
    try {
      const response = await apiService.get(`/streamers/total-live-viewers-now?${queryString}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);

export const fetchTotalRevenueStream = createAsyncThunk(
  "dashboard/fetchTotalRevenueStream",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(payload?.queryParams || {});
    try {
      const response = await apiService.get(`/organizations/total-revenue-stream?${queryString}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error");
    }
  }
);