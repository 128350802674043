// src/redux/users/reducer.ts

import { createSlice } from "@reduxjs/toolkit";
import { getCategoryListData, getQuestionListData, getUserTypeListData, registerUser } from "./actions";


// Declaration of initial state model
//that will be use in Components using "useSelector(state => state.sliceName)"
const initialState = {
  organizationCategory: {
    data: null,
    isLoading: false,
    success: null,
    error: null,
  },
  agencyCategory: {
    data: null,
    isLoading: false,
    success: null,
    error: null,
  },
  
  securityQuestion: {
    data: null,
    isLoading: false,
    success: null,
    error: null,
  },
  streamCategory: {
    data: null,
    isLoading: false,
    success: null,
    error: null,
  },
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder

    .addCase(getUserTypeListData.pending, (state, action) => {
      const { userType } = action.meta.arg
      state[`${userType}Category`].isLoading = true;
    })
    .addCase(getUserTypeListData.fulfilled, (state, action) => {
      const { userType } = action.meta.arg
      const data = {
        ...
        action.payload.d,
        list: action.payload.d.list.map(item => {
          return {
            ...item,
            value: item.id,
            label: item.name,
          }
        })
      };
      const message = action.payload.m;

      state[`${userType}Category`].data = data;
      state[`${userType}Category`].isLoading = false;
      state[`${userType}Category`].success = message || `Successfully Fetched User Me`;

    })
    .addCase(getUserTypeListData.rejected, (state, action) => {
      const { userType } = action.meta.arg

      state[`${userType}Category`].isLoading = false;
      state[`${userType}Category`].success = null;
      state[`${userType}Category`].error = action.payload.m || `Error Fetched User Me`;
    })
    
    .addCase(getQuestionListData.pending, (state, action) => {
      state.securityQuestion.isLoading = true;
    })
    .addCase(getQuestionListData.fulfilled, (state, action) => {
      const data = {
        ...
        action.payload.d,
        list: action.payload.d.list.map(item => {
          return {
            ...item,
            value: item.id,
            label: item.question,
          }
        })
      };

      const message = action.payload.m;

      state.securityQuestion.data = data;
      state.securityQuestion.isLoading = false;
      state.securityQuestion.success = message || `Successfully Fetched User Me`;

    })
    .addCase(getQuestionListData.rejected, (state, action) => {
      state.securityQuestion.isLoading = false;
      state.securityQuestion.success = null;
      state.securityQuestion.error = action.payload.m || `Error Fetched User Me`;
    })

    .addCase(getCategoryListData.pending, (state, action) => {
      state.streamCategory.isLoading = true;
    })
    .addCase(getCategoryListData.fulfilled, (state, action) => {
      const data = {
        ...
        action.payload.d,
        list: action.payload.d.list.map(item => {
          return {
            ...item,
            value: item.id,
            label: item.name,
          }
        })
      };

      const message = action.payload.m;

      state.streamCategory.data = data;
      state.streamCategory.isLoading = false;
      state.streamCategory.success = message || `Successfully Fetched streamCategory`;

    })
    .addCase(getCategoryListData.rejected, (state, action) => {
      state.streamCategory.isLoading = false;
      state.streamCategory.success = null;
      state.streamCategory.error = action.payload.m || `Error Fetched streamCategory`;
    })
    
  },
});

export default categorySlice.reducer;
