const adminSideBarContent = [
  { link: "/admin/dashboard", icon: "bx bxs-dashboard", label: "Dashboard" },
  {
    link: "/admin/organizations",
    icon: "fas fa-users",
    label: "Organizations",
  },
  { link: "/admin/agencies", icon: "fas fa-users", label: "Agencies" },
  { link: "/admin/streamers", icon: "fas fa-users", label: "Streamers" },
  { link: "/admin/users", icon: "fas fa-users", label: "Users" },
  {
    link: "/admin/pending-registrations",
    icon: "fas fa-users",
    label: "Pending Registrations",
  },
  // { link: "/admin/payout", icon: "fas fa-coins", label: "Payout" },
  // { link: "/admin/analytics", icon: "bx bx-bar-chart", label: "Analytics" },
  // { link: "/admin/chats", icon: "bx bxs-chat", label: "Chats" },
  // { link: "/admin/notifications-settings", icon: "fas fa-bell", label: "Notification Settings" },
  // { link: "/admin/ai-and-analytics", icon: "bx bx-bar-chart", label: "AI and Analytics" },
  // { link: "/admin/security-and-privacy", icon: "bx bx-cog", label: "Security and Privacy" },
  // { link: "/admin/live-streaming", icon: "bx bx-chat", label: "Live Streaming" },
  // { link: "/admin/gifts-and-monetization", icon: "bx bx-chat", label: "Gifts and Monetization" },
  // { link: "/admin/trending-streams", icon: "bx bx-line-chart", label: "Trending Streams" },
  // { link: "/admin/flag-contents", icon: "bx bxs-flag", label: "Flag Contents" },
  // { link: "/admin/user-accounts", icon: "fas fa-users", label: "User Accounts" },
  // { link: "/admin/polls-and-qa", icon: "bx bxs-bar-chart-alt-2", label: "Polls and Q&A" },
  // { link: "/admin/gv-cloud", icon: "bx bx-cloud-lightning", label: "GV Cloud" },
  // { link: "/admin/virtual-events", icon: "bx bx-video", label: "Virtual Events" },
]

const organizationSideBarContent = [
  {
    link: "/organization/dashboard",
    icon: "bx bxs-dashboard",
    label: "Dashboard",
  },
  // { link: "/organization/agencies", icon: "fas fa-users", label: "Agencies" },
  // { link: "/organization/streamers", icon: "fas fa-users", label: "Streamers" },
  // { link: "/organization/payout", icon: "fas fa-coins", label: "Payout" },
  // { link: "/organization/penalty", icon: "fas fa-coins", label: "Penalty" },
  // { link: "/organization/chats", icon: "bx bxs-chat", label: "Chats" },
  // { link: "/organization/notifications-settings", icon: "fas fa-bell", label: "Notification Settings" },
  // { link: "/organization/ai-and-analytics", icon: "bx bx-bar-chart", label: "AI and Analytics" },
  // { link: "/organization/security-and-privacy", icon: "bx bx-chat", label: "Security and Privacy" },
]

const agencySideBarContent = [
  { link: "/agency/dashboard", icon: "bx bxs-dashboard", label: "Dashboard" },
  { link: "/agency/streamers", icon: "fas fa-users", label: "Streamers" },
  { link: "/agency/payout", icon: "fas fa-coins", label: "Payout" },
  { link: "/agency/penalty", icon: "fas fa-coins", label: "Penalty" },
  // { link: "/agency/penalty", icon: "fas fa-coins", label: "Penalty" },
]

const streamerSideBarContent = [
  { link: "/streamer/dashboard", icon: "bx bxs-dashboard", label: "Dashboard" },
  { link: "/streamer/payout", icon: "fas fa-coins", label: "Payout" },
  { link: "/streamer/penalty", icon: "fas fa-coins", label: "Penalty" },
]

export {
  adminSideBarContent,
  organizationSideBarContent,
  agencySideBarContent,
  streamerSideBarContent,
}
