import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { useSelector } from "store";
import { fetchUserMe } from "store/redux/user/actions";

const Authmiddleware = (props) => {
  const dispatch = useDispatch();
  const { isAuthenticated, role } = useSelector(
    (state) => state.auth
  );
  
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUserMe());
    }
  }, [])
  

  if (isAuthenticated) {
    if (props.role === role) { // Checking currnet login role and rederict to authorized pages based on the current role
      return <React.Fragment>
      {props.children}
    </React.Fragment>
    } else {
      return <Navigate to="/unauthorized" />
    }
  } else {
    return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  }
};

export default Authmiddleware;
