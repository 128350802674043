import { createAsyncThunk } from "@reduxjs/toolkit"
import apiService from "helpers/api_service"
import buildQueryString from "helpers/buildQueryString"

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (
    { userType, queryParams },
    { getState, rejectWithValue, dispatch }
  ) => {
    const queryString = buildQueryString(queryParams)
    try {
      const response = await apiService.get(
        `/users/${userType === "users" ? "" : userType}?${queryString}`
      )

      return response.data
    } catch (error) {
      return rejectWithValue(error.message ?? "Error ")
    }
  }
)

export const fetchUsersDetails = createAsyncThunk(
  "user/fetchUsersDetails",
  async ({ userId, userType }, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await apiService.get(`/${userType}/${userId}`)

      return response.data
    } catch (error) {
      return rejectWithValue(error.message ?? "Error")
    }
  }
)

export const fetchUserMe = createAsyncThunk(
  "user/fetchUserMe",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await apiService.get(`/users/me`)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message ?? "Error")
    }
  }
)

export const updateApplicationStatus = createAsyncThunk(
  "user/updateApplicationStatus",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.put(
        `/users/${payload.userId}/approved-applications`,
        payload.data,
        {}
      )
      // console.log("--ACTION requestPayout API RESPONSE: ", response);
      return response.data
    } catch (error) {
      // throw new Error("Error logging in: " + error.message);
      return rejectWithValue(error.message ?? "Error updateApplicationStatus")
    }
  }
)

export const fetchActiveFollowersByStreamerRole = createAsyncThunk(
  "user/fetchActiveFollowersByStreamerRole",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const queryString = buildQueryString(payload.queryParams || {})

    try {
      const response = await apiService.get(
        `/streamers/my-followers/${payload.userId}?${queryString}`
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.message ?? "Error")
    }
  }
)

export const fetchPendingRegistrations = createAsyncThunk(
  "user/fetchPendingRegistrations",
  async (queryParams, { getState, rejectWithValue }) => {
    // const queryString = buildQueryString(queryParams)
    try {
      const response = await apiService.get(`/users/pending-registrations`)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message ?? "Error")
    }
  }
)
