import { createAsyncThunk } from "@reduxjs/toolkit";
import { setCookie } from "helpers/cookies";
import apiService from "helpers/api_service";
import allowedRoles from "constants/roles";
import { setNotAllowedUserRoleErrorMessage } from "./reducer";

export const loginUsingEmail = createAsyncThunk(
  "auth/loginUsingEmail",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await apiService.post(`/signin/email`, payload);
      const data = response.data?.d
      if (allowedRoles[data.role]) {
        // const date = new Date();
        // date.setTime(date.getTime() + (10 * 1000));
          setCookie(
            "signinUserData",
            {
              tokens: data.tokens,
              role: data.role,
              id: data.id
            },
            // { expires:date }
          ); // this also detects by middleware.js but only on first page load or each visit of any specific page
      } else {
        dispatch(setNotAllowedUserRoleErrorMessage({ message: "Role type is not allowed!" }))
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error logging in");
    }
  }
);

export const createUser = createAsyncThunk(
  "auth/createUser",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.post(`/public/${payload.roleType}/register`, payload.data, {}, true);
      // console.log("--ACTION createUser API RESPONSE: ", response);
      return response.data;
    } catch (error) {
      // throw new Error("Error logging in: " + error.message);
      return rejectWithValue(error.message ?? "Error createUser");
    }
  }
);