import React, { Fragment } from 'react'
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import PropTypes from "prop-types";
import styled from 'styled-components';

const InputField = ({ 
  name, 
  type,
  placeholder,
  label,
  formik,
  isRequired,
  inputRef,
  ...props 
}) => {
  return (
    <Fragment>
      <FormGroup>
        {label && <Label className="fs-5">{label}{isRequired &&<span className="text-danger"> *</span>}</Label>}
        <StyledInputField
          name={name}
          type={type || "text"}
          placeholder={placeholder}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name] || ""}
          invalid={
            formik.touched[name] && formik.errors[name] ? true : false
          }
          innerRef={inputRef}
          // className="bg-transparent"
          // {...formik}
          {...props}
        />
        {formik.touched[name] && formik.errors[name] ? (
          <FormFeedback type="invalid">{formik.errors[name]}</FormFeedback>
        ) : null}
      </FormGroup>
    </Fragment>
  )
}

export default InputField

const StyledInputField = styled(Input)`
  font-size: 12px;
  background: rgba(58, 49, 58, 1);
  border:none;
  height: 40px;
  border-radius: ${({ type }) => ["text", "number", "date", "upload"].includes(type) ? "100px" : "10px" };
  &:focus {
    background: rgba(58, 49, 58, 1);
  }
  &::placeholder {
    font-weight: 300;
  }
`

InputField.propTypes = {
  name: PropTypes.string, 
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  formik: PropTypes.any,
  isRequired: PropTypes.bool,
  props: PropTypes.any,
};