import { createSlice } from "@reduxjs/toolkit";
import { fetchAvailableBalance, fetchGiftHistory, fetchLastPayout, fetchMontlyProfit, fetchOrganizationIncomeHistory, fetchForyouPayoutByAgency, fetchPayoutHistory, fetchPayoutHistoryByCurrentSession, fetchPayoutList, fetchWeeklyEarnings, fetchPastPayoutByAgency, fetchPastPayoutByStreamer, fetchAuditTrailRevenueByAgency, fetchAuditTrailRevenueByOrganization } from "./actions";
import { error } from "toastr";

const initialState = {
  data: null,
  payoutDetails: null,
  isLoading: false,
  error: null,
  success: null,
  message: null,
  availableBalance: {
    isLoading: false,
    success: null,
    error: null,
    total: 0,
  },
  monthlyProfit: {
    isLoading: false,
    success: null,
    error: null,
    total: 0,
  },
  weeklyEarnings: {
    isLoading: false,
    success: null,
    error: null,
    total: 0,
  },
  lastPayout: {
    isLoading: false,
    success: null,
    error: null,
    total: 0,
  },
  payoutHistory: {
    details: null,
    data: null,
    list: [],
    isLoading: false,
    success: null,
    error: null,
  },
  pastPayoutHistory: {
    details: null,
    data: null,
    list: [],
    isLoading: false,
    success: null,
    error: null,
  },
  giftingHistory: {
    details: null,
    data: null,
    list: [],
    isLoading: false,
    success: null,
    error: null,
  },
  organizationIncomeHistory: {
    details: null,
    data: null,
    list: [],
    isLoading: false,
    success: null,
    error: null,
  },
};

const payoutSlice = createSlice({
  name: "payout",
  initialState,
  reducers: {
    forceClearAllUserState: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayoutList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchPayoutList.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;

        state.data = data;
        state.isLoading = false;
        state.success = `Successfully Fetched Data`;
        state.message = message;

      })
      .addCase(fetchPayoutList.rejected, (state, action) => {
        const message = action.error.message || action.payload.m
        state.isLoading = false;
        state.success = false;
        state.error = message || `Error Fetched Data`;
        state.message = message || "An error occurred";
      })

      // GET AVAILABLE BALANCE
      .addCase(fetchAvailableBalance.pending, (state, action) => {
        state.availableBalance.isLoading = true;
      })
      .addCase(fetchAvailableBalance.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;

        state.availableBalance.total = data.totalAmount;
        state.availableBalance.isLoading = false;
        state.availableBalance.success = message || `Successfully Fetched Data`;

      })
      .addCase(fetchAvailableBalance.rejected, (state, action) => {
        const message = action.error.message || action.payload.m
        state.availableBalance.isLoading = false;
        state.availableBalance.success = null;
        state.availableBalance.error = message || "An error occurred";
      })

      // GET MONTHLY PROFIT
      .addCase(fetchMontlyProfit.pending, (state, action) => {
        state.monthlyProfit.isLoading = true;
      })
      .addCase(fetchMontlyProfit.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;

        state.monthlyProfit.data = data;
        state.monthlyProfit.isLoading = false;
        state.monthlyProfit.success = message || `Successfully Fetched Data`;

      })
      .addCase(fetchMontlyProfit.rejected, (state, action) => {
        const message = action.error.message || action.payload.m
        state.monthlyProfit.isLoading = false;
        state.monthlyProfit.success = null;
        state.monthlyProfit.error = message || "An error occurred";
      })
      
      // GET WEEKLY EARNIS
      .addCase(fetchWeeklyEarnings.pending, (state, action) => {
        state.weeklyEarnings.isLoading = true;
      })
      .addCase(fetchWeeklyEarnings.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;

        state.weeklyEarnings.data = data;
        state.weeklyEarnings.isLoading = false;
        state.weeklyEarnings.success = message || `Successfully Fetched Data`;

      })
      .addCase(fetchWeeklyEarnings.rejected, (state, action) => {
        const message = action.error.message || action.payload.m
        state.weeklyEarnings.isLoading = false;
        state.weeklyEarnings.success = null;
        state.weeklyEarnings.error = message || "An error occurred";
      })

      // GET LAST PAYOUT
      .addCase(fetchLastPayout.pending, (state, action) => {
        state.lastPayout.isLoading = true;
      })
      .addCase(fetchLastPayout.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;

        state.lastPayout.data = data;
        state.lastPayout.isLoading = false;
        state.lastPayout.success = message || `Successfully Fetched Data`;

      })
      .addCase(fetchLastPayout.rejected, (state, action) => {
        const message = action.error.message || action.payload.m
        
        state.lastPayout.isLoading = false;
        state.lastPayout.success = null;
        state.lastPayout.error = message || "An error occurred";
      })

      // GET PAYOUT HISTORY
      .addCase(fetchPayoutHistory.pending, (state, action) => {
        state.payoutHistory.isLoading = true;
      })
      .addCase(fetchPayoutHistory.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        const list = data.list;

        state.payoutHistory.data = data;
        state.payoutHistory.list = list;
        state.payoutHistory.isLoading = false;
        state.payoutHistory.success = message || `Successfully Fetched Data`;

      })
      .addCase(fetchPayoutHistory.rejected, (state, action) => {
        const message = action.error.message || action.payload.m

        state.payoutHistory.isLoading = false;
        state.payoutHistory.success = null;
        state.payoutHistory.error = message || "An error occurred";
      })

      // GET PAYOUT HISTORY BY SESSION
      .addCase(fetchPayoutHistoryByCurrentSession.pending, (state, action) => {
        state.payoutHistory.isLoading = true;
      })
      .addCase(fetchPayoutHistoryByCurrentSession.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        const list = data.list;

        state.payoutHistory.data = data;
        state.payoutHistory.list = list;
        state.payoutHistory.isLoading = false;
        state.payoutHistory.success = message || `Successfully Fetched Data`;

      })
      .addCase(fetchPayoutHistoryByCurrentSession.rejected, (state, action) => {
        const message = action.error.message || action.payload.m

        state.payoutHistory.isLoading = false;
        state.payoutHistory.success = null;
        state.payoutHistory.error = message || "An error occurred";
      })

      // GET GIFTING HISTORY
      .addCase(fetchGiftHistory.pending, (state, action) => {
        state.giftingHistory.isLoading = true;
      })
      .addCase(fetchGiftHistory.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        const list = data.list;

        state.giftingHistory.data = data;
        state.giftingHistory.list = list;
        state.giftingHistory.isLoading = false;
        state.giftingHistory.success = message || `Successfully Fetched Data`;

      })
      .addCase(fetchGiftHistory.rejected, (state, action) => {
        const message = action.error.message || action.payload.m

        state.giftingHistory.isLoading = false;
        state.giftingHistory.success = null;
        state.giftingHistory.error = message || "An error occurred";
      })

      // GET ORGANIZATION INCOME HISTORY
      .addCase(fetchOrganizationIncomeHistory.pending, (state, action) => {
        state.organizationIncomeHistory.isLoading = true;
      })
      .addCase(fetchOrganizationIncomeHistory.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        const list = data.list;

        state.organizationIncomeHistory.data = data;
        state.organizationIncomeHistory.list = list;
        state.organizationIncomeHistory.isLoading = false;
        state.organizationIncomeHistory.success = message || `Successfully Fetched Data`;

      })
      .addCase(fetchOrganizationIncomeHistory.rejected, (state, action) => {
        const message = action.error.message || action.payload.m

        state.organizationIncomeHistory.isLoading = false;
        state.organizationIncomeHistory.success = null;
        state.organizationIncomeHistory.error = message || "An error occurred";
      })
      
       // GET PAYOUT HISTORY BY SESSION
       .addCase(fetchForyouPayoutByAgency.pending, (state, action) => {
        state.payoutHistory.isLoading = true;
      })
      .addCase(fetchForyouPayoutByAgency.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        const list = data.list;

        state.payoutHistory.data = data;
        state.payoutHistory.list = list;
        state.payoutHistory.isLoading = false;
        state.payoutHistory.success = message || `Successfully Fetched Data`;

      })
      .addCase(fetchForyouPayoutByAgency.rejected, (state, action) => {
        const message = action.error.message || action.payload.m

        state.payoutHistory.isLoading = false;
        state.payoutHistory.success = null;
        state.payoutHistory.error = message || "An error occurred";
      })

      // GET PAYOUT HISTORY BY SESSION
      .addCase(fetchPastPayoutByAgency.pending, (state, action) => {
        state.payoutHistory.isLoading = true;
      })
      .addCase(fetchPastPayoutByAgency.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        const list = data.list;

        state.pastPayoutHistory.data = data;
        state.pastPayoutHistory.list = list;
        state.pastPayoutHistory.isLoading = false;
        state.pastPayoutHistory.success = message || `Successfully Fetched Data`;

      })
      .addCase(fetchPastPayoutByAgency.rejected, (state, action) => {
        const message = action.error.message || action.payload.m

        state.pastPayoutHistory.isLoading = false;
        state.pastPayoutHistory.success = null;
        state.pastPayoutHistory.error = message || "An error occurred";
      })

      // GET PAYOUT HISTORY BY SESSION
      .addCase(fetchPastPayoutByStreamer.pending, (state, action) => {
        state.pastPayoutHistory.isLoading = true;
      })
      .addCase(fetchPastPayoutByStreamer.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        const list = data.list;

        state.pastPayoutHistory.data = data;
        state.pastPayoutHistory.list = list;
        state.pastPayoutHistory.isLoading = false;
        state.pastPayoutHistory.success = message || `Successfully Fetched Data`;

      })
      .addCase(fetchPastPayoutByStreamer.rejected, (state, action) => {
        const message = action.error.message || action.payload.m

        state.pastPayoutHistory.isLoading = false;
        state.pastPayoutHistory.success = null;
        state.pastPayoutHistory.error = message || "An error occurred";
      })

      .addCase(fetchAuditTrailRevenueByAgency.pending, (state, action) => {
        state.payoutHistory.isLoading = true;
      })
      .addCase(fetchAuditTrailRevenueByAgency.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        const list = data.list;

        state.payoutHistory.data = data;
        state.payoutHistory.list = list;
        state.payoutHistory.isLoading = false;
        state.payoutHistory.success = message || `Successfully Fetched Data`;

      })
      .addCase(fetchAuditTrailRevenueByAgency.rejected, (state, action) => {
        const message = action.error.message || action.payload.m

        state.payoutHistory.isLoading = false;
        state.payoutHistory.success = null;
        state.payoutHistory.error = message || "An error occurred";
      })

      .addCase(fetchAuditTrailRevenueByOrganization.pending, (state, action) => {
        state.payoutHistory.isLoading = true;
      })
      .addCase(fetchAuditTrailRevenueByOrganization.fulfilled, (state, action) => {
        const data = action.payload.d;
        const message = action.payload.m;
        const list = data.list;

        state.payoutHistory.data = data;
        state.payoutHistory.list = list;
        state.payoutHistory.isLoading = false;
        state.payoutHistory.success = message || `Successfully Fetched Data`;

      })
      .addCase(fetchAuditTrailRevenueByOrganization.rejected, (state, action) => {
        const message = action.error.message || action.payload.m

        state.payoutHistory.isLoading = false;
        state.payoutHistory.success = null;
        state.payoutHistory.error = message || "An error occurred";
      })
      
      
  },
});

export const { 
} = payoutSlice.actions;

export default payoutSlice.reducer;
