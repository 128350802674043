// Front
import Layout from "./layout/reducer";

import { combineReducers } from "@reduxjs/toolkit";
import authReducer from './redux/auth/reducer';
import userReducer from './redux/user/reducer';
import payoutReducer from './redux/payout/reducer';
import analyticReducer from './redux/analytic/reducer';
import dashboardReducer from './redux/dashboard/reducer';
import categoryReducer from './redux/category/reducer';

const rootReducer = combineReducers({
  // OLD FROM TEMPLATE
  Layout,

  // NEW FROM SCRATCH
  auth: authReducer,
  user: userReducer,
  payout: payoutReducer,
  analytic: analyticReducer,
  dashboard: dashboardReducer,
  category: categoryReducer,
});

export default rootReducer;
